/*
document.querySelectorAll(".portfolio-details-button").forEach(btn => {
    btn.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelectorAll(".portfolio-details-container").forEach(container => {
            if(!container.classList.contains("2xl:scale-y-0")) {
                container.classList.add("2xl:scale-y-0");
            }
        });

        btn.parentElement.parentElement.parentElement.querySelector(".portfolio-details-container").classList.remove("2xl:scale-y-0");
        //_paq.push(['trackEvent', 'DetailsClicked', btn.dataset.detailsFor]);
    });
});

document.querySelectorAll(".portfolio-details-close-button").forEach(btn => {
    btn.addEventListener("click", function (e) {
        e.preventDefault();
        btn.parentElement.classList.add("2xl:scale-y-0");
    });
});
*/

if(window.safari === undefined) {
    const coverLogoElement = document.querySelector("#cover #logo-container");
    const coverLogoTextElement = document.querySelector("#cover h2");
    const coverScrollDownElement = document.querySelector("#cover a");
    const coverElementHeight = document.querySelector("#cover").getBoundingClientRect().height;

    window.addEventListener("scroll", function () {
        let coverScrollPercentage = Math.min(100, (window.scrollY / coverElementHeight) * 100);
        coverLogoElement.style.transform = `translateY(${Math.round(coverScrollPercentage * 6)}px) scale(${1 + (coverScrollPercentage / 400)})`;

        coverLogoTextElement.style.opacity = `${100 - (coverScrollPercentage * 2.5)}%`;
        coverScrollDownElement.style.opacity = `${100 - (coverScrollPercentage * 4)}%`;
    });
}